import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from "reselect"
import { loginOperations } from "../../../state/auth"
import { Link } from 'gatsby'

const LogoutForm = ({signOutUser}) => {

    useEffect(()=> {
        signOutUser()
    },[])

    return (
      <p>
          You have been signed out successfully<br/><Link to="/">Go back to home page</Link>
      </p>
    );
}

const mapStateToProps = createStructuredSelector({

});

const mapDispatchToProps = {
    signOutUser: loginOperations.signOutUser,
};

LogoutForm.propTypes = {
    signOutUser: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps) (LogoutForm);
