import React from "react"
import LogoutForm from "../components/UI/LogoutForm/LogoutForm"
import * as style from "./logout.module.scss"

import Layout from "../components/Layout"

const PageLogout = () => {

  return (
    <Layout
      staticSeo={{ seoTitle: "Logout", seoDescription: "Logout page" }}
      location={{ pathname: "logout" }}
    >
      <div className={style.container}>
        <LogoutForm/>
      </div>
    </Layout>
  )
}

export default PageLogout